.rn-section-wrap {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  border-right: 1px solid #999;
  border-collapse: collapse;
}

.rn-section-row {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
}
