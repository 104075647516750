.rn-controller-wrap {
  width: 100%;
  padding: 0 2px;
}

.rn-controller-buttons {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  padding-top: 0.3em;
}

.rn-controller-actionButtons {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  width: 100%;
  padding-top: 0.3em;
}
