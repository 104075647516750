.select-page-self {
  padding-top: 16px;
  padding-bottom: 64px;
  max-width: 700px;
}

.select-page-lite-item {
  margin-bottom: 1.5em;
  padding-left: 0 !important;
  margin-left: 0 !important;
  margin-right: 2em !important;
}

.select-page-list-item-header {
  position: relative;
}

.select-page-badge {
  opacity: 1;
  position: absolute;
  right: -1.6em;
  top: 0;
}
