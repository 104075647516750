.puzzle-page-self {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.puzzle-page-game {
  padding-top: 32px;
  margin: auto;
}

.puzzle-page-back-wrap {
  width: calc(24.3em + 6px);
}

.puzzle-page-back-button {
  box-shadow: none;
  cursor: pointer;
  color: rgb(71, 160, 150);
}
