.rn-numberplace-wrap {
  display: flex;
  flex-wrap: wrap;
  width: calc(13.5em + 6px);
  font-size: 1.8em;
  color: #333;
  background-color: white;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rn-warning {
  color: #b98e12;
  text-align: right;
  width: 100%;
  font-size: small;
  margin-top: 4px;
}
