.rn-buttons-number {
  font-size: 0.9em;
  width: 1.3em;
  height: 1.3em;
  line-height: 1.3em;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  user-select: none;
  box-sizing: content-box;
}

.rn-buttons-nomalColor {
  color: rgb(71, 160, 150);
  border: 2px solid rgba(71, 160, 150, 0.8);
}

.rn-buttons-nomalColor:hover {
  color: rgb(61, 140, 131);
  border: 2px solid rgb(61, 140, 131);
  background-color: rgba(61, 140, 131, 0.05);
}

.rn-buttons-nomalColor:active {
  color: rgb(45, 111, 104);
  border: 2px solid rgb(45, 111, 104);
  background-color: rgba(45, 111, 104, 0.1);
}

.rn-buttons-annoColor {
  color: rgb(190, 147, 77);
  border: 2px solid rgb(190, 147, 77);
}

.rn-buttons-annoColor:hover {
  color: rgb(170, 157, 67);
  border: 2px solid rgb(170, 157, 67);
  background-color: rgba(170, 157, 67, 0.05);
}

.rn-buttons-annoColor:active {
  color: rgb(150, 137, 57);
  border: 2px solid rgb(150, 137, 57);
  background-color: rgba(150, 137, 57, 0.1);
}

.rn-buttons-annoActiveColor {
  color: white;
  background-color: rgb(190, 147, 77);
  border: 2px solid rgb(190, 147, 77);
}

.rn-buttons-action {
  font-size: 0.7em;
  width: 4em;
  height: 1.6em;
  line-height: 1.6em;
  box-sizing: content-box;
  font-weight: 700;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
  user-select: none;
  margin-left: 8px;
}
