.rn-board-wrap {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  box-sizing: border-box;
  border-top: 2px solid #999;
  border-right: 1px solid #999;
  border-left: 1px solid #999;
  cursor: pointer;
  user-select: none;
}

.rn-board-row {
  display: flex;
  flex-wrap: nowrap;
  border-bottom: 1px solid #999;
}
