.rn-cell-wrap {
  width: 1.5em;
  height: 1.5em;
  line-height: 1.5em;
  box-sizing: border-box;
  border-collapse: collapse;
  border-left: 1px solid #999;
  border-bottom: 1px solid #999;
  text-align: center;
}

.rn-cell-annoWrap {
  width: 100%;
}

.rn-cell-row {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
}

.rn-cell-fixed {
  font-weight: 700;
}

.rn-cell-focused {
  background-color: rgba(0, 0, 0, 0.1);
}
